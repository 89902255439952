import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { GameMode } from '../../consts';
import { ISettledBet, RespinSuccessMultipleType, RespinSymbolType } from '../../global.d';
import {
  setBetAmount,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrency,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setNextResult,
  setRespinCnt,
  setRespinSymbolType,
} from '../../gql/cache';
import i18n from '../../i18next';
import { isBaseGameMode, isFreeSpinsMode, normalizeCoins, showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine';
import {
  BASE_WIN_AMOUNT_LIMIT,
  EventTypes,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
  messageWindowAutoResizeTextStyles,
} from '../config';

const RespinSuccessMultipleNumToType: RespinSuccessMultipleType[] = [1, 1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 100];

class MessageWindow extends PIXI.Container {
  private frame: TickerSpine<'message_frame'>;

  private spinCounter: number;

  private messageText: AutoResizeText;

  constructor() {
    super();

    this.frame = new TickerSpine('message_frame');
    this.frame.visible = true;
    this.spinCounter = 0;

    this.messageText = this.initMessageText();

    this.addChild(this.frame, this.messageText);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.CREATE_SOLDOUT_MESSAGE_BANNER, this.onSoldOutMessageBanner.bind(this));

    eventManager.addListener(EventTypes.SET_IS_IN_TRANSITION, this.transitionChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.onWinMessageBanner.bind(this));

    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = false;
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = true;
    });

    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      if (!isAutoSpins) this.messageText.visible = true;
    });

    this.x = SLOTS_CONTAINER_WIDTH / 2;
    this.y = 500 + 280 + 128 / 2;
  }

  private initMessageText(): AutoResizeText {
    const messageText = new AutoResizeText(i18n.t('messageWindow.op'), messageWindowAutoResizeTextStyles);
    messageText.x = 0;
    messageText.y = 7;
    messageText.anchor.set(0.5);
    /*
    messageText.style.fill = messageText.context.createPattern(
      PIXI.Loader.shared.resources[ResourceTypes.textPatternMessageFrame]!.data,
      'repeat',
    )!;
    */
    return messageText;
  }

  private updateText(text: string) {
    this.messageText.text = text;
    this.messageText.visible = true;
  }

  private getNextFeatureSuccessCount = (add: number): number => {
    let successCnt = setRespinCnt() ?? 0;
    successCnt += add;
    return Math.min(RespinSuccessMultipleNumToType.length - 1, successCnt);
  };

  private transitionChange(isInTransition: boolean) {
    if (!isInTransition && isBaseGameMode(setGameMode()) && !setIsAutoSpins() && setBrokenBuyFeatureGame() === '') {
      this.updateText(i18n.t('messageWindow.idol'));
    }
  }

  private onStartSpin() {
    if (isFreeSpinsMode(setGameMode())) {
      const successCount = this.getNextFeatureSuccessCount(1);

      this.updateText(
        i18n.t('messageWindow.respin', {
          amount: formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(
              setBetAmount() *
                this.getRespinMultiplierFromSymbol(setRespinSymbolType()) *
                RespinSuccessMultipleNumToType[successCount]!,
            ),
            showCurrency: showCurrency(setCurrency()),
          }),
        }),
      );
    } else {
      const messages: string[] = i18n.t('messageWindow.spin', { returnObjects: true });
      this.updateText(messages[this.spinCounter]!);
      this.spinCounter = (this.spinCounter + 1) % messages.length;
    }
  }

  private onReelStopped() {
    if (!isFreeSpinsMode(setGameMode()) && setNextResult()?.paylines.length === 0) {
      this.updateText(i18n.t('messageWindow.idol'));
      if (setIsAutoSpins()) {
        this.messageText.visible = false;
      }
    }
  }

  private onStartWinAnimation(_nextResult: ISettledBet): void {
    if (isFreeSpinsMode(setGameMode())) {
      const successCount = this.getNextFeatureSuccessCount(0); //updated successCount

      this.updateText(
        i18n.t('messageWindow.rswin', {
          bet: formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setBetAmount() * this.getRespinMultiplierFromSymbol(setRespinSymbolType())),
            showCurrency: showCurrency(setCurrency()),
          }),
          multi: RespinSuccessMultipleNumToType[successCount],
        }),
      );
    } else {
      this.updateText(i18n.t('messageWindow.win'));
    }
  }

  private onModeChange(settings: { mode: GameMode }) {
    const message = isFreeSpinsMode(settings.mode) ? 'messageWindow.trigger' : 'messageWindow.idol';
    this.updateText(i18n.t(message));
    if (
      (!isFreeSpinsMode(settings.mode) && setIsAutoSpins()) ||
      (isFreeSpinsMode(settings.mode) && setBrokenGame() && setRespinCnt() > 0)
    ) {
      this.messageText.visible = false;
    }

    if (isFreeSpinsMode(settings.mode) && setIsContinueAutoSpinsAfterFeature()) {
      this.updateText('');
    }
  }

  private onWinMessageBanner() {
    const multiplier = setFreeSpinsTotalWin() / setBetAmount();
    const message = multiplier >= BASE_WIN_AMOUNT_LIMIT ? 'messageWindow.rsend2' : 'messageWindow.rsend1';

    this.updateText(i18n.t(message));
  }

  private onSoldOutMessageBanner() {
    const message = 'messageWindow.soldout';
    this.updateText(i18n.t(message));
  }

  private getRespinMultiplierFromSymbol = (symbol: RespinSymbolType): number => {
    const MultiplierBySlotId: Record<RespinSymbolType, number> = {
      A: 1.0,
      B: 0.9,
      C: 0.8,
      D: 0.7,
      E: 0.6,
    };
    return MultiplierBySlotId[symbol]!;
  };
}

export default MessageWindow;
